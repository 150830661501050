import React from 'react'
import { useQuery } from 'react-query'
import CircularProgress from '@material-ui/core/CircularProgress'
import Backdrop from '@material-ui/core/Backdrop'

import { bootstrapAppData } from '@graniot/common/adapters/bootstrap'
import * as authClient from '@graniot/common/adapters/auth-client'
import { useConfirmSignUpMutation } from '../adapters/signup'

const AuthContext = React.createContext()

function AuthProvider(props) {
	const [firstAttemptFinished, setFirstAttemptFinished] =
		React.useState(false)
	const [confirmingSignUp, setConfirmingSignUp] = React.useState(false)

	const [loginError, setLoginError] = React.useState(null)
	const [user, setUser] = React.useState(null)
	const [loggedUser, setLoggedUser] = React.useState(null)
	const [clients, setClients] = React.useState(null)
	const [forgotPassword, setForgotPassword] = React.useState(false)
	const { isSuccess, isLoading, error, data, isError, refetch } = useQuery({
		queryKey: ['user_data'],
		queryFn: bootstrapAppData,
		refetchOnWindowFocus: false,
		enabled: false, // turned off by default, manual refetch is needed
	})
	const mutateconfirmsignup = useConfirmSignUpMutation()

	React.useEffect(() => {
		if (mutateconfirmsignup.isSuccess && !data) {
			const url = new URL(location)
			url.searchParams.delete('auth_mail_verification')
			history.pushState(null, document.title, url)

			setConfirmingSignUp(false)
			refetch()
		}
	}, [mutateconfirmsignup])

	React.useLayoutEffect(() => {
		if (isSuccess) {
			setFirstAttemptFinished(true)
		}
	}, [isSuccess])

	React.useLayoutEffect(() => {
		if (data) {
			if (data.user?.is_superuser || data.user?.tenant_superuser)
				setUser(data.user.clients.find(user => user.username === 'demo') || data.user.clients[0])
			else setUser(data.user)
		}
		setClients(data?.user?.clients)
		setLoggedUser(data?.user)
	}, [data])

	React.useEffect(() => {
		const url = new URL(location)
		let search = window.location.search
		let params = new URLSearchParams(search)
		let auth_id = params.get('auth_id')
		let auth_mail_verification = params.get('auth_mail_verification')
		let company = params.get('company')

		if (auth_id) {
			url.searchParams.delete('auth_id')
			history.pushState(null, document.title, url)
			setForgotPassword(true)
			authClient.handleToken(auth_id)
			refetch()
		} else if (auth_mail_verification) {
			authClient.handleToken(auth_mail_verification)
			setConfirmingSignUp(true)
			mutateconfirmsignup.mutate([company])
		} else {
			const token = authClient.getToken()
			if (token && !isSuccess) {
				refetch()
			}
		}
	}, [])

	if (!firstAttemptFinished) {
		if (isLoading || confirmingSignUp) {
			return (
				<Backdrop open={true}>
					<CircularProgress color="inherit" />
				</Backdrop>
			)
		}
		if (isError) {
			return (
				<div css={{ color: 'red' }}>
					<p>Uh oh... There's a problem. Try refreshing the app.</p>
					<pre>{error}</pre>
				</div>
			)
		}
	}

	const login = (form) =>
		authClient
			.login(form)
			.then(refetch)
			.catch((err) => {
				setLoginError(err)
			})
	const register = (form) => authClient.register(form).then(refetch)
	const logout = () => authClient.logout().then(setUser(null)).then(refetch)

	const selectUser = (user) => {
		setUser(user)
	}

	const daysLeftInFreeTrial = () => {
		if (loggedUser?.company && loggedUser.company.date_end_free_trial) {
			const start = new Date()
			const end = new Date(loggedUser.company.date_end_free_trial)
			if (start < end) {
				const diffTime = Math.abs(end - start)
				return Math.ceil(diffTime / (1000 * 60 * 60 * 24))
			} else {
				return 0
			}
		}

		return 0
	}

	const isClientInFreeTrial = () => {
		if (loggedUser) {
			if (loggedUser.is_superuser) return false

			if (loggedUser?.company) {
				if (
					loggedUser.company.client_free_trial &&
					loggedUser.company.date_start_free_trial &&
					loggedUser.company.date_end_free_trial &&
					daysLeftInFreeTrial() > 0 &&
					!loggedUser.company.client_paying &&
					!loggedUser.company.early_adopter
				) {
					return true
				}
			}
		}

		return false
	}

	const isClientPaying = () => {
		return loggedUser?.is_client_paying ?? false;
	};

	const isClientFreeTrialExpired = () => {
		if (loggedUser) {
			if (loggedUser.is_superuser) return false

			if (loggedUser?.company) {
				if (
					daysLeftInFreeTrial() === 0 &&
					!isClientPaying() &&
					!loggedUser.company.early_adopter
				) {
					return true
				}
			}
		}

		return false
	}


	const isClientHasAlreadyHadExtension = () => {
		if (loggedUser) {
			if (loggedUser.is_superuser) return false
			// no extenstion for now
			// return true

			if (loggedUser?.company) {
				if (
					loggedUser.company.survey &&
					loggedUser.company.survey_date
				) {
					return true
				}
			}
		}

		return false
	}

	const isCompanyAdmin = () => {
		if (loggedUser.graniot_groups.includes('COMPANY_ADMIN')) {
			return true
		}
		return false
	}

	const isTecnician = () => {
		if (loggedUser.graniot_groups.includes('TECNICIAN')) {
			return true
		}
		return false
	}

	const setUserProfile = (data) => {
		if (!loggedUser.is_superuser) {
			setUser({ ...loggedUser, profile: { ...data } })
			setLoggedUser({ ...loggedUser, profile: { ...data } })
		}
	}

	const signupConfirmed = mutateconfirmsignup.isSuccess && !!loggedUser;

	return (
		<AuthContext.Provider
			value={{
				loggedUser,
				user,
				login,
				logout,
				register,
				loginError,
				selectUser,
				clients,
				forgotPassword,
				setForgotPassword,
				isClientInFreeTrial,
				daysLeftInFreeTrial,
				isClientFreeTrialExpired,
				isClientPaying,
				isClientHasAlreadyHadExtension,
				isCompanyAdmin,
				isTecnician,
				refetch,
				setUserProfile,
				signupConfirmed,
				resetSignupConfirming: () => mutateconfirmsignup.reset()
			}}
			{...props}
		/>
	)
}

function useAuth() {
	const context = React.useContext(AuthContext)
	if (context === undefined) {
		throw new Error(`useAuth must be used within a AuthProvider`)
	}
	return context
}

export { AuthProvider, useAuth }
